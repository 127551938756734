<template>
  <topic-post-card
    :post.sync="document.text"
    :postColor="postColor"
    :createAt="document.createAt"
    :deletePost="deletePostTopic"
    :dense="dense"
    :filled="filled"
    :index="index"
    :outlined="outlined"
    :placeholder="placeholder"
    :rows="rows"
    :savePost="sendInternaly"
    :showActionButton="!document.id"
    :showEditButton="canUpdate && !!document.id"
    :showUserInformation="!!document.id"
    :update-at="document.updateAt"
    :userId="createBy"
    ref="topic-post"
  />
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    TopicPostCard: () => import('./topic-post-card')
  },
  data () {
    return {
      isConfidential: true,
      edit: false,
      oldText: null
    }
  },
  methods: {
    async sendInternaly () {
      this.isConfidential = true
      const promise = await this.saveDocument()
      return promise
    },
    async sendExternaly () {
      this.isConfidential = false
      const promise = await this.saveDocument()
      return promise
    },
    async save () {
      if (this.id) {
        await this.$http().post(`/core/v6/posts/${this.id}`, this.postToJSON())
      } else {
        await this.$http().post(`/core/v6/topics/${this.topicId}/posts`, this.postToJSON())
      }
    },
    postToJSON () {
      return {
        emoji: '',
        text: this.document.text,
        confidential: this.isConfidential
      }
    },
    editPost () {
      this.edit = true
      this.oldText = this.document.text
    },
    cancelPost () {
      this.edit = false
      this.document.text = this.oldText
    },
    async deletePostTopic () {
      await this.$http().delete(`/core/v6/posts/${this.id}`)
      this.refreshCache()
    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.PostDetail
    },
    id () {
      return this.postId
    },
    createBy () {
      return this.document.createBy ?? this.$store.getters.currentUser.id
    },
    canUpdate () {
      return this.createBy === this.$store.getters.currentUser.id
    },
    postColor () {
      return !this.document.confidential && !!this.document.id ? 'secondary' : undefined
    }
  },
  props: {
    postId: Number,
    dense: Boolean,
    filled: Boolean,
    index: Number,
    outlined: Boolean,
    placeholder: String,
    rows: {
      type: Number,
      default: 1
    },
    topicId: Number
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-input.v-text-field.v-input--is-readonly .v-input__slot::before
  border none
</style>
